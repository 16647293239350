/* --- MOBILE STYLES --- */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
}

.aboutContainer {
  background-color: var(--bg2-color);
  padding: var(--spacing) 0;
}

.personalImage {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing);
}

.personalImage img {
  height: 250px;
  z-index: 50;
  border-radius: 250px;
  border: 1px solid var(--hl2-color);
  opacity: 1;
  padding: 10px;
  filter: contrast(1.2);
  background-color: white;
}

.contentContainer {
  padding: 10px;
}

.contentContainer h4 {
  color: var(--hl-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.contentContainer h5 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 15px;
  letter-spacing: -0.2px;
}

.contentDescription {
  color: var(--secondary-text-color);
  letter-spacing: 0.3px;
  line-height: 1.7;
}

.infoContainer {
  border-top: 1px solid var(--grey);
  margin-top: var(--sm-spacing);
  padding-top: var(--sm-spacing);
}

.info {
  padding-left: 0;
}

.infoContainer span {
  color: var(--text-color);
}

.infoContainer p {
  color: var(--secondary-text-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.contentContainer a {
  text-decoration: none;
  color: var(--text-color);
}
.contentContainer .btn {
  margin-top: var(--sm-spacing);
}

.arrow-icon {
  font-size: 20px;
  margin-left: 5px;
  margin-right: -10px;
  transition: transform 0.3s ease; /* Add a smooth transition */
}

.skill {
  width: 75px;
  height: 75px;
  margin: 10px;
}

.tooltip {
  position: relative; 
  display: inline-block;
  border-bottom: 1px dotted white; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  /* padding: 5px 0; */
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.skills {
  color: white;
  margin-top: 50px;
  margin-bottom: 50px;
}

.skillsTitle {
  margin-bottom: 10px;
}

@media (min-width: 595px) {
  .contentContainer {
    padding: var(--sm-spacing);
  }
}

@media (min-width: 750px) {
  .aboutContainer {
    padding: var(--spacing);
  }
}

@media (min-width: 992px) {
  .personalImage img {
    height: 430px;
    z-index: 50;
    /* border-radius: 0; */
  }

  .personalImage {
    margin: auto 0;
    height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
